import { useEffect, useMemo } from 'react'
import Router, { useRouter } from 'next/router'
import { getAllInvestments } from '@/services/api/endpoints'
import { getUserIVAs, IVA } from '@/services/api/IVA/IVA'
import useApi, { ApiRes } from '@/services/hooks/useApiV2'
import useNotifications from '@/services/hooks/useNotifications'
import { Investment } from '@/services/api/investment/types'
import useUserInvestedCampaigns from '@/services/hooks/useUserInvestedCampaigns'
import useAuth from '@/services/hooks/useAuth'
import ABTester, { ABTestProps } from '@/components/ABTester'

interface Props {
  disabled?: boolean
}

const swrOptions = {
  revalidateIfStale: false,
  revalidateOnFocus: false,
  revalidateOnReconnect: false,
}

export const BellNotificationSignal: React.FC<Props> = ({
  disabled = false,
}) => {
  const { addNotification, removeNotification, updateNotification } =
    useNotifications()
  const { userInvestedCampaigns: campaigns } = useUserInvestedCampaigns()

  const { data: IVAs = [] }: ApiRes<IVA[]> = useApi({
    ...getUserIVAs(),
    swrOptions,
  })

  const { data: investments = [] }: ApiRes<Investment[]> = useApi({
    ...getAllInvestments(),
    swrOptions,
  })

  useEffect(() => {
    if (!investments.length || !campaigns || disabled) return

    investments
      .filter((i) => !i.completed && !i.canceled)
      .forEach((i) => {
        const campaign = campaigns.find((c) => c.slug === i.campaign)
        if (!campaign?.currentlyFunding) return

        addNotification({
          id: `incomplete-investment-${i.id}`,
          type: 'bell',
          text: `Finish your investment in ${campaign.name}`,
          onClick: () => {
            Router.push(`/investments`)
          },
          seen: false,
        })
      })
  }, [investments, campaigns, addNotification, disabled])

  useEffect(() => {
    if (!IVAs.length || !investments.length || !campaigns || disabled) return

    IVAs.forEach((IVA) => {
      const bellId = `iva-bell-${IVA.id}`
      const toastId = `iva-toast-${IVA.id}`
      const investment = investments.find((i) => i.id === IVA.investmentId)
      const campaign = campaigns.find((c) => c.slug === investment?.campaign)
      const text = `We need some information to complete your investment in ${campaign?.name}`

      if (!campaign?.currentlyFunding || IVA?.status !== 'Pending User Action')
        return

      addNotification({
        id: bellId,
        type: 'bell',
        text,
        onClick: () => {
          // if taking action, remove associated toast notification
          Router.push(`/investments/${IVA.investmentId}/verify`)
          removeNotification(toastId)
        },
        seen: false,
      })

      addNotification({
        id: toastId,
        type: 'toast',
        variant: 'error',
        text,
        actions: [
          {
            label: 'Update',
            onClick: () => {
              // if taking action, remove associated bell notification
              Router.push(`/investments/${IVA.investmentId}/verify`)
              updateNotification(bellId, { seen: true })
            },
          },
        ],
      })
    })
  }, [
    IVAs,
    investments,
    campaigns,
    addNotification,
    removeNotification,
    updateNotification,
    disabled,
  ])

  return null
}

const GatedNotificationSignal = () => {
  const { user } = useAuth()
  const router = useRouter()
  const inFunnel = useMemo(() => router.asPath.includes('/invest/'), [router])

  if (!user) return null

  return (
    <ABTester name="user_notifications_guard_funnel">
      {({ decision }: ABTestProps) => {
        return (
          <BellNotificationSignal
            disabled={decision.variationKey === 'on' && inFunnel}
          />
        )
      }}
    </ABTester>
  )
}

export default GatedNotificationSignal
